import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';
import { imageClassName } from '@archipro-design/aria';

export const VariantDot: StyleRule = () => ({
    [`& .${imageClassName}`]: {
        width: '100%',
        height: '100%',
        border: `solid ${pxToRem(0.5)} transparent`,
        '& img': {
            position: 'absolute',
        },
    },
});
