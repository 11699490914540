import type { VariantColorProps } from '@archipro-design/aria';
import { useStyles, VariantColor } from '@archipro-design/aria';
import * as S from './VariantDot.style';

const VariantDot = ({ variables = {}, ...rest }: VariantColorProps) => {
    const { css } = useStyles();

    return (
        <VariantColor
            className={css(S.VariantDot)}
            variables={{
                containerBorder: '0 none',
                border: '0 none',
                ...variables,
            }}
            {...rest}
        />
    );
};
export default VariantDot;
